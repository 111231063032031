<template>
  <div class="project-activity is-flex is-align-items-center is-align-content-space-between	 py-4">
    <figure class="image is-48x48">
      <img class="is-rounded" :src="activity.author.avatar_url" :alt="activity.author.name">
    </figure>
    <div class="project-activity__content px-5 is-flex-grow-2 is-align-self-stretch">
      <h5 class="title is-6 mb-1">{{ activity.author.name }}</h5>
      <div class="is-flex">
        <div class="project-activity__action is-capitalized">{{ activity.action_name }}</div>
        <div v-html="content" class="is-inline-block ml-1"></div>
      </div>
    </div>
    <div class="project-activity__time has-text-weight-bold has-text-black-bis">
      {{ dateActivity }}
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'Activity',
    props: {
      activity: {
        type: Object
      }
    },
    data () {
      return {
        content: '',
        time: ''
      }
    },
    computed: {
      dateActivity () {
        return moment(this.activity.created_at).fromNow();
      }
    },
    methods: {
      handleActivityData() {
        switch (this.activity.action_name) {
          case "commented on":
          case "closed":
            this.content += '<span class="has-text-weight-medium has-text-success">' + this.activity.target_title + '</span>'
            break;

          case "deleted":
            this.content += '<span class="has-text-weight-medium has-text-danger">' + this.activity.push_data.ref_type + ' ' + this.activity.push_data.ref + '</span>'
            break;

          case "pushed to":
            this.content += '<span class="has-text-weight-medium has-text-danger">' + this.activity.push_data.ref_type + ' ' + this.activity.push_data.ref + ': </span><span class="is-italic"> ' + this.activity.push_data.commit_title + '</span>'
            break;

          case "accepted":
          case "opened":
            this.content += 'merge request <span class="has-text-weight-medium has-text-info">' + this.activity.target_title + '</span>'
            break;

        }
      },
    },

    mounted() {
      this.handleActivityData()
    },
  }
</script>

<style lang="scss" scoped>
  .project-activity {
    border-bottom: 1px solid #dbdbdb;
  }
</style>
